import 'suneditor/dist/css/suneditor.min.css'
import SunEditor from 'suneditor'
import plugins from 'suneditor/src/plugins'
import lang from 'suneditor/src/lang'
window.SunEditor = SunEditor
window.plugins = plugins
window.lang = lang

/*
import './bootstrap';
import Alpine from 'alpinejs'
window.Alpine = Alpine
Alpine.start()
*/
